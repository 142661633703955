import { Button } from '@ufx-ui/core'
import axios from 'axios'
import cx from 'classnames'
import React, { useState, useReducer } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import { apiURL } from '../../config'
import Input from '../core/Input'
import Textarea from '../core/Textarea'
import { reducer, onSubmit as onSubmitHelper } from './Contact.helpers'

function Feedback({ message }) {
  const [showSubmitMsg, setShowSubmitMsg] = useState(false)
  const [formState, dispatch] = useReducer(reducer, {})
  const { errors } = formState

  const onSubmit = () =>
    onSubmitHelper(formState, dispatch, async () => {
      const response = await axios.post(apiURL('/api/contact'), {
        name: formState.name,
        email: formState.email,
        comment: formState.body,
      })
      const { status, data: { ok } = {} } = response

      if (status === 200 && ok === true) {
        setShowSubmitMsg(true)
      }
    })

  return (
    <Col xs={12} className='first'>
      <div className='heading'>Let’s Talk</div>
      <div className='divider' />
      <div
        className={cx('submit-container', { 'hide-on-submit': showSubmitMsg })}
      >
        <p className='para-text'>
          If you have any questions, problems with the website, or other
          feedback, let us know!
        </p>
        <Row className='input-wrapper'>
          <Col xs={12} md={4}>
            <Input
              placeholder='Name'
              value={formState.name}
              onChange={value =>
                dispatch({
                  type: 'update',
                  payload: {
                    name: 'name',
                    value,
                  },
                })
              }
              error={errors?.name}
            />
          </Col>
          <Col xs={12} md={4}>
            <Input
              placeholder='Email'
              value={formState.email}
              onChange={value =>
                dispatch({
                  type: 'update',
                  payload: {
                    name: 'email',
                    value,
                  },
                })
              }
              error={errors?.email}
            />
          </Col>
          <Col xs={12} md={8}>
            <Textarea
              placeholder='Tell us about your suggestions'
              onChange={e =>
                dispatch({
                  type: 'update',
                  payload: {
                    name: 'body',
                    value: e.target.value,
                  },
                })
              }
              value={formState.body}
            />
            <div className='error-msg'>{errors?.body}</div>
          </Col>
          <Col xs={12}>
            <Button intent='warning' className='submit' onClick={onSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      </div>
      <div
        className={cx('submit-message', { 'show-on-submit': showSubmitMsg })}
      >
        {message}
      </div>
    </Col>
  )
}

export default Feedback
