import React from 'react'

import Contact from '../components/Contact'
import FooterMenu from '../components/FooterMenu'
import Header from '../components/Header'
import Layout from '../components/Layout'
import PageContent from '../components/PageContent'
import SEO from '../components/SEO'

function ContactPage() {
  return (
    <Layout>
      <SEO title='Contact' />
      <PageContent>
        <Header />
        <Contact />
        <br />
        <FooterMenu />
      </PageContent>
    </Layout>
  )
}

export default ContactPage
