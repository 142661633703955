import _isEmpty from 'lodash/isEmpty'

const INVALID_EMAIL = 'Please enter a valid email address.'
const REQUIRED_ERROR = 'Required Field'

/* eslint-disable import/prefer-default-export */
export const reducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'update':
      return {
        ...state,
        [payload.name]: payload.value,
      }
    case 'set_error':
      return {
        ...state,
        errors: {
          ...state.errors,
          [payload.name]: payload.value,
        },
      }
    default:
      return state
  }
}

export const isValidEmail = (email = '') =>
  email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)

export const onSubmit = (formState, dispatch, onSuccess) => {
  let hasError = false
  let error = ''

  if (_isEmpty(formState.name)) {
    hasError = true
    error = REQUIRED_ERROR
  } else {
    error = ''
  }
  dispatch({
    type: 'set_error',
    payload: {
      name: 'name',
      value: error,
    },
  })

  if (_isEmpty(formState.email)) {
    hasError = true
    error = REQUIRED_ERROR
  } else if (!isValidEmail(formState.email)) {
    hasError = true
    error = INVALID_EMAIL
  } else {
    error = ''
  }
  dispatch({
    type: 'set_error',
    payload: {
      name: 'email',
      value: error,
    },
  })

  if (_isEmpty(formState.body)) {
    hasError = true
    error = REQUIRED_ERROR
  } else {
    error = ''
  }
  dispatch({
    type: 'set_error',
    payload: {
      name: 'body',
      value: error,
    },
  })

  if (!hasError) {
    onSuccess()
  }
}
