import React from 'react'

function Textarea(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <textarea className='biz-textarea' {...props} />
  )
}

export default Textarea
