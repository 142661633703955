import React from 'react'
import { Grid, Row } from 'react-flexbox-grid'

import MsgSubmit from '../../images/svg/message-submit.svg'
import Feedback from './Feedback'
import NominateGuest from './NominateGuest'

function Contact() {
  const message = (
    <>
      <MsgSubmit />
      <br />
      <div className='highlight-primary'>Message sent!</div>
      <br />
      <p className='para-text'>We’ll respond as soon as possible!</p>
    </>
  )

  return (
    <Grid fluid>
      <Row className='contact-section'>
        <Feedback message={message} />
        <NominateGuest message={message} />
      </Row>
    </Grid>
  )
}

export default Contact
